<template>
  <div id="modalsOfImportList">
    <div>
      <b-modal
        id="idModalSelectFile"
        ref="modalSelectFile"
        class="modalStyle"
        no-close-on-backdrop
        no-close-on-esc
        centered
        title="Importar Produtos"
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <div
                class="listDropFileBox pt-2 mb-2"
                @dragover="dragOver"
                @drop="dropFile"
              >
                <input
                  id="add_file"
                  ref="refProductFile"
                  type="file"
                  class="d-none"
                  :accept="['.csv']"
                  @change="handleFile"
                >
                <span
                  class="d-flex justify-content-center px-2 mb-1"
                >
                  <svg-icon
                    type="mdi"
                    size="50"
                    :path="mdiFileUploadOutline"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-1 mt-2 fixLayoutTextModal"
                >
                  Arraste e solte o arquivo .CSV aqui para importar a lista de produtos
                </span>
                <div class="d-flex justify-content-center">
                  <span
                    class="d-inline-flex flex-column justify-content-center align-items-center px-2 mb-0 mt-1 mb-2 fixLayoutTextModal"
                  >
                    <b-button
                      id="confirm-btn"
                      class="modal-btn-file my-50 w-100"
                      variant="primary"
                      @click="selectFile()"
                    >
                      Selecionar Arquivo
                    </b-button>
                    <b-button
                      id="download-model-btn"
                      class="modal-btn-file  my-50 w-100"
                      variant="outline-primary"
                      @click="downloadCSV()"
                    >
                      Baixar Modelo
                    </b-button>
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="idModalLoadingFile"
        ref="modalLoadingFile"
        class="modalStyle"
        centered
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mt-1 mb-3"
              >
                <b-spinner
                  variant="success_2"
                  label="Spinning"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 pb-2 my-2 fixLayoutTextModal"
              >
                {{ textLoading }}
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="idModalVerifySuccess"
        ref="modalVerifySuccess"
        class="modalStyle"
        centered
        title="Confirmar?"
        hide-footer
      >
        <b-form
          class="mt-1 pb-3"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalVerifySuccess"
              >
                <svg-icon
                  type="mdi"
                  size="50"
                  :path="mdiFileCheckOutline"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 pb-1 my-1 textVerify"
              >
                Lista de produtos validada!
              </span>
              <div class="d-flex justify-content-center">
                <b-button
                  id="import-btn"
                  class="modal-btn-file mr-50"
                  variant="primary"
                  @click="importFile()"
                >
                  Importar
                </b-button>
                <b-button
                  id="cancel-import-btn"
                  class="modal-btn-file ml-50"
                  variant="outline-primary"
                  @click="closeModalVerifySuccess()"
                >
                  Cancelar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="idModalVerifyUnsuccess"
        ref="modalVerifyUnsuccess"
        class="modalStyle"
        centered
        hide-footer
      >
        <b-form
          class="mt-1 pb-2"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-2 layoutModal layoutModalUnsuccess"
              >
                <svg-icon
                  type="mdi"
                  size="50"
                  :path="mdiMessageAlertOutline"
                />
              </span>
              <span
                class="d-flex justify-content-center px-50 pb-2 my-1 textVerify"
              >
                Falha ao importar lista de produtos, ela precisa atender aos padrões de preenchimento
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="d-flex justify-content-center align-items-centered"
            >
              <ul class="importErrors">
                <li
                  v-for="(error, index) in productsImportErrors"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="idModalImportSuccess"
        ref="modalImportSuccess"
        class="modalStyle"
        centered
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalSuccess"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :path="mdiCheckCircle"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 pb-2 my-1 fixLayoutTextModal"
              >
                Lista Importada com sucesso!
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import {
  mdiFileUploadOutline,
  mdiMessageAlertOutline,
  mdiFileCheckOutline,
  mdiCheckCircle,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    SvgIcon,
  },
  data() {
    const productsImportErrors = []
    const textLoading = ''
    const selectedFile = null
    const columnName = {
      price: 'Preço',
      supplier: 'CNPJ Fornecedor',
      unity: 'Unidade',
      warranty: 'Garantia',
      classifier: 'Categoria',
      type: 'Tipo',
      expiration_date: 'Data de validade',
      weight: 'Peso',
      lenght: 'Profundidade',
      width: 'Largura',
      height: 'Altura',
      model: 'Modelo',
      id: 'Id',
    }

    return {
      productsImportErrors,
      textLoading,
      selectedFile,
      columnName,
    }
  },
  methods: {
    openModalSelectFileImport() {
      this.$refs.modalSelectFile.show()
    },
    closeModalSelectFileImport() {
      this.$refs.modalSelectFile.hide()
    },
    openModalLoadingFileImport(text) {
      this.textLoading = text
      this.$refs.modalLoadingFile.show()
    },
    closeModalLoadingFileImport() {
      this.$refs.modalLoadingFile.hide()
    },
    openModalVerifySuccess() {
      this.$refs.modalVerifySuccess.show()
    },
    closeModalVerifySuccess() {
      this.$refs.modalVerifySuccess.hide()
    },
    openModalVerifyUnsuccess() {
      this.$refs.modalVerifyUnsuccess.show()
    },
    closeModalVerifyUnsuccess() {
      this.$refs.modalVerifyUnsuccess.hide()
    },
    openModalImportSuccess() {
      this.$refs.modalImportSuccess.show()
    },
    closeModalImportSuccess() {
      this.$refs.modalImportSuccess.hide()
    },
    downloadCSV() {
      const columns = [
        'id', 'Nome', 'Descrição', 'Marca', 'Modelo', 'Preço', 'Nº Anvisa',
        'Código', 'Link do fornecedor', 'CNPJ Fornecedor', 'Garantia', 'Unidade',
        'Categoria', 'Tipo', 'Data de validade', 'Peso', 'Profundidade',
        'Largura', 'Altura',
      ]

      const csvContent = `${columns.join(',')}\n`
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', 'Modelo para importar produto.csv')
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },
    selectFile() {
      this.$refs.refProductFile.click()
    },
    dragOver(event) {
      event.preventDefault()
    },
    dropFile(event) {
      event.preventDefault()
      this.$refs.refProductFile.files = event.dataTransfer.files
      this.processFile(this.$refs.refProductFile.files[0])
    },
    handleFile(event) {
      this.processFile(event.target.files[0])
    },
    processFile(file) {
      this.closeModalSelectFileImport()
      this.openModalLoadingFileImport('Validando lista de produtos, aguarde...')
      this.productsImportErrors = []

      this.selectedFile = file
      if (this.selectedFile.size > 15000000) {
        this.productsImportErrors.push('O arquivo deve ter tamanho de até 15MB')
      }
      const allowedExtensions = /\.(csv)$/i
      if (!allowedExtensions.test(this.selectedFile.name)) {
        this.productsImportErrors.push('O arquivo deve ter a extensão .csv')
      }
      if (this.productsImportErrors.length > 0) {
        setTimeout(() => {
          this.closeModalLoadingFileImport()
          this.openModalVerifyUnsuccess()
        }, 100)
        return
      }

      const formData = new FormData()
      formData.append('file', this.selectedFile)

      syslic.product.importProduct.verifyImportProductByFile(formData)
        .then(response => {
          const hasError = response.data.message
          if (hasError.valid) {
            this.closeModalLoadingFileImport()
            this.openModalVerifySuccess()
          } else {
            Object.keys(hasError.errors).forEach(lineKey => {
              const lineNumber = lineKey.replace('Line ', '')
              const lineErrors = hasError.errors[lineKey]

              Object.keys(lineErrors).forEach(field => {
                const errors = lineErrors[field]

                errors.forEach(() => {
                  if (this.productsImportErrors.length >= 10) {
                    return
                  }
                  let columnErrorName = 'Null'
                  if (field) {
                    columnErrorName = this.columnName[field]
                  }
                  const errorMessage = `Linha ${lineNumber} com erro na coluna ${columnErrorName}.`
                  this.productsImportErrors.push(errorMessage)
                })
              })
            })
            this.closeModalLoadingFileImport()
            this.openModalVerifyUnsuccess()
          }
        })
        .catch(() => {
          this.productsImportErrors.push('Falha ao verificar o arquivo, por favor entre em contato com o administrador do sistema.')

          this.closeModalLoadingFileImport()
          this.openModalVerifyUnsuccess()
        })
    },
    importFile() {
      this.closeModalVerifySuccess()
      this.openModalLoadingFileImport('Importando lista de produtos, aguarde...')
      this.productsImportErrors = []

      const formData = new FormData()
      formData.append('file', this.selectedFile)

      syslic
        .product
        .importProduct
        .importProductByFile(formData)
        .then(() => {
          this.closeModalLoadingFileImport()
          this.openModalImportSuccess()
        })
        .catch(() => {
          if (this.productsImportErrors.length === 0) {
            this.productsImportErrors.push('Falha ao importar, por favor entre em contato com o administrador do sistema.')
          }
          this.closeModalLoadingFileImport()
          this.openModalVerifyUnsuccess()
        })
    },
  },
  setup() {
    return {
      mdiFileUploadOutline,
      mdiMessageAlertOutline,
      mdiFileCheckOutline,
      mdiCheckCircle,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.importErrors {
  font-weight: 400;
  font-size: 1.1rem;
  color: #FF7E07;
}

.textVerify {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
}

.layoutModalVerifySuccess {
  svg {
    color: $primary;
  }
}

.listDropFileBox {
  border: 1px solid #d8d6de;
  background-color: #f3f2f7;
  border-radius: 5px;
  text-align: center;
}
</style>
