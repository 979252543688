<template>
  <div>
    <div>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">
            <b-card
              no-body
              class="mb-0"
            >
              <div class="mr-2 ml-2 mt-1 mb-1">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-1"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-between pl-0 pr-50"
                  >
                    <div>
                      <div>
                        <b-dropdown
                          v-b-tooltip.hover
                          title="Exibir colunas"
                          class="dropBtnTableToolbar mt-2"
                          toggle-class="rounded-circle customDropdown"
                          variant="link"
                          no-caret
                          offset="-120"
                          :dropup="true"
                          :boundary="'scrollParent'"
                        >

                          <template #button-content>
                            <b-button
                              v-b-tooltip.hover
                              class="btn-icon rounded-circle"
                              variant="flat-primary"
                              size="md"
                              title="Exibir colunas"
                            >
                              <svg-icon
                                type="mdi"
                                size="22"
                                :path="mdiTableEdit"
                              />
                            </b-button>
                          </template>

                          <b-dropdown-text
                            class="showColumnsDropdown"
                          >
                            Exibir Colunas
                          </b-dropdown-text>

                          <b-dropdown-divider />
                          <b-dropdown-form>
                            <b-form-checkbox
                              v-for="(column, index) in ajustableFields"
                              :key="index"
                              v-model="tableColumns[column.index].visible"
                              :value="true"
                              :unchecked-value="false"
                              class="mb-1"
                            >
                              {{ column.label }}
                            </b-form-checkbox>
                          </b-dropdown-form>

                        </b-dropdown>
                      </div>
                    </div>

                    <div
                      v-if="hasPermissionToRead"
                    >
                      <b-dropdown
                        id="actionsDropdown"
                        class="mt-2"
                        toggle-class="rounded-circle customDropdown"
                        variant="none"
                        size="md"
                        no-caret
                        offset="-160"
                        :boundary="'scrollParent'"
                      >
                        <template
                          #button-content
                        >
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="20"
                          />
                        </template>

                        <b-dropdown-item
                          id="dropdownExportBtn"
                          class="d-flex align-items-center justify-content-center"
                          @click="confirmOrCancelExport()"
                        >
                          <svg-icon
                            type="mdi"
                            size="24"
                            :path="mdiFileDownloadOutline"
                          />
                          <span
                            class="ml-50 align-middle font-weight-bold"
                          >
                            Exportar Lista
                          </span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          id="dropdownImporttBtn"
                          class="d-flex align-items-center justify-content-center"
                          @click="openModalImport()"
                        >
                          <svg-icon
                            type="mdi"
                            size="24"
                            :path="mdiFileUploadOutline"
                          />
                          <span
                            class="ml-50 align-middle font-weight-bold"
                          >
                            Importar Lista
                          </span>
                        </b-dropdown-item>

                      </b-dropdown>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <b-table
                id="product_table"
                class="position-relative"
                primary-key="id"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há produtos cadastrados."
                :items="products"
                :fields="visibleFields"
                :busy="isLoading"
                :sort-by.sync="sortOptions.sortBy"
                :sort-desc.sync="sortOptions.isSortDirDesc"
                @row-clicked="edit"
              >
                <template #table-busy>
                  <div class="text-center my-3">
                    <b-spinner
                      variant="info"
                      class="align-middle mb-1"
                    />
                    <br>
                    <strong class="pt-2">Carregando ...</strong>
                  </div>
                </template>

                <template #cell(id)="data">
                  <b-media vertical-align="center">
                    <b-link
                      :to="{ name: 'product-item-detail', params: { id: data.item.id } }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </b-media>
                </template>
                <template #cell(code)="data">
                  <span
                    v-if="data.item.code"
                    class="font-weight-bold text-nowrap"
                  >
                    {{ data.item.code }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </template>

                <template #cell(name)="data">
                  <b-media vertical-align="center">
                    {{ data.item.name }}
                  </b-media>
                </template>

                <template #cell(brand)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.brand">
                      {{ data.item.brand }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(model)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.model">
                      {{ data.item.model }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(type__name)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.type_set">
                      {{ data.item.type_set.name }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(classifier__name)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.classifier_set">
                      {{ data.item.classifier_set.name }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(supplier__name)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.supplier_set">
                      {{ data.item.supplier_set.name }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(anvisa)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.anvisa">
                      {{ data.item.anvisa }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(warranty__name)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.warranty_set">
                      {{ data.item.warranty_set.name }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(unity__symbol)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.unity_set">
                      {{ data.item.unity_set.symbol }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(expiration_date)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.expiration_date">
                      {{ convertDate(data.item.expiration_date) }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(link_supplier)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.link_supplier">
                      {{ data.item.link_supplier }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <template #cell(price)="data">
                  <b-media vertical-align="center">
                    <span v-if="data.item.get_price">
                      R$ {{ formatCurrency(data.item.get_price.toFixed(2)) }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </b-media>
                </template>

                <!-- Column: Actions -->
                <template #cell(field_actions)="data">
                  <div
                    :class="totalItems > 1 ? '' : 'dropdownfix'"
                  >
                    <b-dropdown
                      id="actions"
                      variant="link"
                      no-caret
                      :dropup="true"
                      :boundary="'scrollParent'"
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        v-if="hasPermissionToRead && !hasPermissionToUpdate"
                        :to="{ name: 'product-item-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToUpdate"
                        :to=" { name: 'product-item-edit', params: { id: data.item.id } } "
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToDelete"
                        @click="showConfirmOrCancelDelete(data.item)"
                      >
                        <svg-icon
                          type="mdi"
                          size="16"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >

                    <b-pagination
                      id="btn_page"
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-button
        id="addItemBtn"
        v-b-tooltip.hover
        class="btn-icon rounded-circle m-3"
        variant="primary"
        size="md"
        :disabled="!hasPermissionToWrite"
        title="Adicionar produto"
        :to="{ name: 'product-item-create'}"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
    <div>
      <div>
        <b-modal
          id="idModalExportSpinner"
          ref="modalExportSpinner"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
        >
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <span
                  class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalLoading"
                >
                  <svg-icon
                    type="mdi"
                    size="70"
                    :path="mdiFolderOpenOutline"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-2 fixLayoutTextModal font-500"
                >
                  Exportando produtos
                </span>
                <span
                  id="modalPercentage"
                  class="d-flex justify-content-center px-2 my-2"
                >
                  {{ exportPercentage }} %
                </span>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="idModalExportUnsuccess"
          ref="modalExportUnsuccess"
          class="modalStyle"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
        >
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <span
                  class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalUnsuccess"
                >
                  <svg-icon
                    type="mdi"
                    size="70"
                    :path="mdiContentSaveAlertOutline"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
                >
                  Falha ao exportar lista de produtos!
                </span>
                <span
                  class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
                >
                  <b-button
                    id="hide-btn"
                    class="modal-btn-fix"
                    variant="primary"
                    @click="closeModalExportUnsuccess()"
                  >
                    Ok
                  </b-button>
                </span>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="idModalExportSuccess"
          ref="modalExportSuccess"
          class="modalStyle"
          centered
          hide-footer
        >
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <span
                  class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalSuccess"
                >
                  <svg-icon
                    type="mdi"
                    size="70"
                    :path="mdiCheckCircle"
                  />
                </span>
                <span
                  class="d-flex justify-content-center px-2 pb-2 my-1 fixLayoutTextModal"
                >
                  Lista de Produtos exportada com sucesso!
                </span>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
    </div>
    <import-product
      ref="importProduct"
    />
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BMedia,
  BModal,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BDropdownDivider,
  BDropdownText,
  BFormCheckbox,
  BPagination,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiPlus,
  mdiTableEdit,
  mdiTrashCanOutline,
  mdiFileDownloadOutline,
  mdiFileUploadOutline,
  mdiFolderOpenOutline,
  mdiContentSaveAlertOutline,
  mdiCheckCircle,
} from '@mdi/js'

import exportFromJSON from 'export-from-json'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'
import ImportProduct from '@/views/product/components/ImportProduct.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BMedia,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BDropdownDivider,
    BDropdownText,
    BFormCheckbox,
    BPagination,
    BSpinner,
    vSelect,
    SvgIcon,
    ImportProduct,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    const products = []
    const perPageOptions = [10, 25, 50, 100]

    const sortOptions = {
      sortBy: 'name',
      isSortDirDesc: false,
    }

    const perPage = 10
    const totalItems = 0
    const totalPages = 0
    const currentPage = 1
    const searchQuery = ''
    const exportPercentage = 0
    const productsImportErrors = []

    const tableColumns = [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
        visible: true,
        fixedColumn: true,
      },
      {
        key: 'code',
        label: 'Código',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'name',
        label: 'Nome',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'brand',
        label: 'Marca',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'model',
        label: 'Modelo',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'type__name',
        label: 'Tipo',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'classifier__name',
        label: 'Categoria',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'price',
        label: 'Preço',
        sortable: true,
        class: 'priceColumn',
        visible: true,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'anvisa',
        label: 'Nº Anvisa',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'supplier__name',
        label: 'Fornecedor',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'warranty__name',
        label: 'Garantia',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'unity__symbol',
        label: 'Unidade',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'expiration_date',
        label: 'Validade',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'link_supplier',
        label: 'Link do Fornecedor',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'productTableColumn',
      },
      {
        key: 'field_actions',
        label: '',
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap productTableAction',
        tdClass: 'productTableAction',
      },
    ]

    const fetchId = ''
    const isLoading = true

    const meta = new MetaData('ProductTable')

    return {
      tableColumns,
      perPageOptions,
      perPage,
      totalItems,
      totalPages,
      exportPercentage,
      productsImportErrors,
      currentPage,
      searchQuery,
      products,
      fetchId,
      isLoading,
      sortOptions,
      meta,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_delete
    },
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
    ajustableFields() {
      const fields = []
      this.tableColumns.forEach((element, index) => {
        if (!element.fixedColumn) {
          fields.push({
            index,
            label: element.label,
          })
        }
      })

      return fields
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
    'sortOptions.sortBy': 'fetchData',
    'sortOptions.isSortDirDesc': 'fetchData',
  },
  created() {
    this.tableColumns = this.meta.bind(this.tableColumns, 'tableColumns')
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    formatCurrency(value) {
      return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending

      const params = {
        p: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
      }

      if (this.sortOptions.isSortDirDesc) {
        params.ordering = `-${this.sortOptions.sortBy}`
      } else {
        params.ordering = this.sortOptions.sortBy
      }

      syslic
        .product
        .item
        .fetchItemsList(params)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.count
            this.totalPages = Math.ceil(response.count / this.perPage)
            this.products = response.results
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos produto.',
                text: 'Não foi possível ler os dados do produto do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.isLoading = false
          }
        })
    },
    deleteItem(item) {
      syslic
        .product
        .item
        .deleteItem(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Item removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover produto.',
              text: 'Não foi possível remover o produto do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o produto.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteItem(item)
          }
        })
    },
    edit(item) {
      router.push({ name: 'product-item-detail', params: { id: item.id } }).catch(() => {})
    },
    convertDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    fetchProductsPage(page) {
      const productFilter = {
        p: page,
        page_size: 10,
      }
      return new Promise((resolve, reject) => {
        syslic
          .product
          .item
          .fetchItemsList(productFilter)
          .then(response => {
            resolve(response.results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async recursiveProductsFetch({ page, data }) {
      const productsPage = await this.fetchProductsPage(page)

      this.exportPercentage = Math.ceil((page / this.totalPages) * 100)

      data.push(...productsPage)

      if (page < this.totalPages) {
        return this.recursiveProductsFetch({ page: page + 1, data })
      }
      return data
    },
    exportProductsCSV() {
      const dataExport = []
      const fileName = 'exportação_produtos'
      const exportType = exportFromJSON.types.csv

      if ((this.products.length === 0)) {
        exportFromJSON({ data: [], fileName, exportType })
        return
      }

      this.openModalExportSpinner()

      this.recursiveProductsFetch({ page: 1, data: [] })
        .then(response => {
          response.forEach(product => {
            const productItem = {
              id: product.id,
              Nome: product.name,
              Descrição: product.description ? product.description : '',
              Marca: product.brand ? product.brand : '',
              Modelo: product.model ? product.model : '',
              Preço: product.get_price ? product.get_price : '',
              'Nº Anvisa': product.anvisa ? product.anvisa : '',
              Código: product.code ? product.code : '',
              'Link do fornecedor': product.link_supplier ? product.link_supplier : '',
              'CNPJ Fornecedor': product.supplier_set ? product.supplier_set.cnpj : '',
              'Nome do fornecedor': product.supplier_set ? product.supplier_set.name : '',
              Garantia: product.warranty_set ? product.warranty_set.name : '',
              Unidade: product.unity_set ? product.unity_set.name : '',
              Categoria: product.classifier_set ? product.classifier_set.name : '',
              Tipo: product.type_set ? product.type_set.name : '',
              'Data de validade': product.expiration_date ? moment(product.expiration_date).format('DD/MM/YYYY') : '',
              Peso: product.weight ? product.weight : '',
              Profundidade: product.depth ? product.depth : '',
              Largura: product.width ? product.width : '',
              Altura: product.height ? product.height : '',
            }
            dataExport.push(productItem)
          })

          exportFromJSON({ data: dataExport, fileName, exportType })

          setTimeout(() => {
            this.openModalExportSuccess()
          })
        })
        .catch(() => {
          this.openModalExportUnsuccess()
        })
        .finally(() => {
          this.closeModalExportSpinner()
          this.exportPercentage = 0
        })
    },
    confirmOrCancelExport() {
      this.$bvModal
        .msgBoxConfirm('Exportar lista de produtos?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-primary',
          bodyClass: 'd-flex align-items-center justify-content-center pt-3 pb-1 font-500',
          footerClass: 'd-flex align-items-center justify-content-center pb-3 border-0',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportProductsCSV()
          }
        })
    },
    openModalExportSpinner() {
      this.$refs.modalExportSpinner.show()
    },
    closeModalExportSpinner() {
      this.$refs.modalExportSpinner.hide()
    },
    openModalExportSuccess() {
      this.$refs.modalExportSuccess.show()
    },
    closeModalExportSuccess() {
      this.$refs.modalExportSuccess.hide()
    },
    openModalExportUnsuccess() {
      this.$refs.modalExportUnsuccess.show()
    },
    closeModalExportUnsuccess() {
      this.$refs.modalExportUnsuccess.hide()
    },
    openModalImport() {
      this.$refs.importProduct.openModalSelectFileImport()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiTrashCanOutline,
      mdiTableEdit,
      mdiFileDownloadOutline,
      mdiFileUploadOutline,
      mdiFolderOpenOutline,
      mdiContentSaveAlertOutline,
      mdiCheckCircle,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.priceColumn {
  max-width: 300px;
  min-width: 180px;
}

#addItemBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

.productTableColumn {
  min-width: 100px;
}

.productTableAction {
  max-width: 5rem;
}

.productTableAction .dropdown-toggle {
  padding-left: 0px;
}

.customDropdown {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 !important;

  height: 3rem !important;
  width: 3rem !important;

  &:hover {
    background-color: rgba(226, 235, 248, 0.882) !important;
  }

  > svg {
    color: $primary;
  }
}

.font-500 {
  font-weight: 500;
  font-size: 1.2rem;
}

#modalPercentage {
  font-size: 2.4rem;
  font-weight: 600;
  color: #6e6b7b;
}
</style>
